import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import Fade from 'react-reveal/Fade';
import Slide from 'react-reveal/Slide';
import KeyboardImg from '../../assets/keyboard-yellow.jpg';
import BulbImg from '../../assets/bulb-yellow.jpg';
import ChoosingIcon from '../../assets/icons/choosing.png';
import AnalyticsIcon from '../../assets/icons/analytics.png';
import PayIcon from '../../assets/icons/pay.png';
import TimeIcon from '../../assets/icons/time.png';

const CareerIntro = () => {
  return(
    <>
      <div className="container-fluid">
        <Slide bottom>
          <Row className="mt-5 pt-5">
            <Col md={4} xs={12} sm={12} className="mb-5">
              <img alt="Ringgit Lab Elite: MPlus Online" src={KeyboardImg} style={{ width: '100%', borderRadius: '10px', boxShadow: 'rgba(50, 50, 93, 0.109804) 1px 2px 2px, rgba(0, 0, 0, 0.0784314) 1px 1px 2px' }} />
            </Col>
            <Col md={1} xs={12} sm={12} className="mb-5"></Col>
            <Col md={7} xs={12} sm={12} className="mb-5 align-self-center">
              <Fade>
                <h1 className="font-weight-bold text-md-left text-center">
                  Marketing Representative Career
                </h1>
                <h2 className="grey-color font-weight-bold pt-2 text-md-left text-center">
                  Career freedom with high income in financial industry
                </h2>
                <div className="divider mt-5"></div>
                <Container>
                  <div className="mt-5">
                    <Row>
                      <Col md={1} sm={2} xs={2}>
                        <img alt="Ringgit Lab Elite: Marketing Representative" src={TimeIcon} style={{ width: '100%' }} />
                      </Col>
                      <Col md={11} sm={10} xs={10}>
                        <h3 className="font-weight-bold" style={{ fontSize: '20px' }}>
                          Flexible schedule
                        </h3>
                        <h4 style={{ fontSize: '20px' }}>
                          Marketing Representative offers you the flexibility to manage your own time and schedule. 
                          Plan your own strategies to build your client base. Don't worry! You can always consult with me too.
                        </h4>
                      </Col> 
                    </Row>
                  </div>
                  <div className="mt-5">
                    <Row>
                      <Col md={1} sm={2} xs={2}>
                        <img alt="Ringgit Lab Elite: Marketing Representative" src={ChoosingIcon} style={{ width: '100%' }} />
                      </Col>
                      <Col md={11} sm={10} xs={10}>
                        <h3 className="font-weight-bold" style={{ fontSize: '20px' }}>
                          Refer and earn
                        </h3>
                        <h4 style={{ fontSize: '20px' }}>
                          Marketing Representative refers and helps with CDS account opening. That's all. 
                          You'll earn 20% commission from brokerage fee of your referees on every transaction.
                        </h4>
                      </Col> 
                    </Row>
                  </div>
                  <div className="mt-5">
                    <Row>
                      <Col md={1} sm={2} xs={2}>
                        <img alt="Ringgit Lab Elite: Marketing Representative" src={PayIcon} style={{ width: '100%' }} />
                      </Col>
                      <Col md={11} sm={10} xs={10}>
                        <h3 className="font-weight-bold" style={{ fontSize: '20px' }}>
                          Part time for passive income
                        </h3>
                        <h4 style={{ fontSize: '20px' }}>
                          Marketing Representative allows you to work as a part-timer. Earn a side income while you can still maintaining 
                          your main job or business. 
                        </h4>
                      </Col> 
                    </Row>
                  </div>
                  <div className="mt-5">
                    <Row>
                      <Col md={1} sm={2} xs={2}>
                        <img alt="Ringgit Lab Elite: Marketing Representative" src={AnalyticsIcon} style={{ width: '100%' }} />
                      </Col>
                      <Col md={11} sm={10} xs={10}>
                        <h3 className="font-weight-bold" style={{ fontSize: '20px' }}>
                          Gain experience in stock market
                        </h3>
                        <h4 style={{ fontSize: '20px' }}>
                          As a Marketing Representative, you'll get the opportunities to gain experience in financial industry 
                          while receiving constant consultation and education about stock market.
                        </h4>
                      </Col> 
                    </Row>
                  </div>
                </Container>
              </Fade>
            </Col>
          </Row>
        </Slide>
        <Slide bottom>
          <Row className="mt-5">
            <Col md={4} xs={12} sm={12} className="mb-5">
              <img alt="Ringgit Lab Elite: MPlus Online" src={BulbImg} style={{ width: '100%', borderRadius: '10px', boxShadow: 'rgba(50, 50, 93, 0.109804) 1px 2px 2px, rgba(0, 0, 0, 0.0784314) 1px 1px 2px' }} />
            </Col>
            <Col md={1} xs={12} sm={12} className="mb-5"></Col>
            <Col md={7} xs={12} sm={12} className="mb-5 align-self-center">
              <Fade>
                <Container>
                  <h3 className="font-weight-bold">
                    What is Marketing Representative?
                  </h3>
                  <div className="divider mt-3"></div>
                  <h4 className="mt-5 pb-5" style={{ fontSize: '24px' }}>
                    Marketing Representative is a person who acts as an introducer for a principal, undertakes marketing of the services,
                    provides client support services and is registered with the principal under Securities
                    Commission of Malaysia.
                  </h4>
                  <h3 className="font-weight-bold mt-5">
                    What are the minimum requirements to become a Marketing Representative?
                  </h3>
                  <div className="divider mt-3"></div>
                  <ol type="1">
                    <li style={{ fontSize: '24px' }}>
                      <h4 className="mt-5" style={{ fontSize: '24px' }}>
                        Must be 21 years old and above
                      </h4>
                    </li>
                    <li style={{ fontSize: '24px' }}>
                      <h4 className="mt-3" style={{ fontSize: '24px' }}>
                        Minimum with a degree or professional qualification or diploma from an institution recognized by the government of Malaysia
                      </h4>
                    </li>
                    <li style={{ fontSize: '24px' }}>
                      <h4 className="mt-3" style={{ fontSize: '24px' }}>
                        Completed and passed the assessment of the online familiarisation programme held by Securities Industry Development Corporation (SIDC) 
                      </h4>
                    </li>
                  </ol>
                </Container>
              </Fade>
            </Col>
          </Row>
        </Slide>
      </div>
    </>
  )
}
export default CareerIntro;