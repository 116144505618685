import React, { useEffect } from 'react';
import Header from '../header/Header';
import Footer from '../footer/Footer';
import { Helmet } from 'react-helmet';
import AccountOpening from './AccountOpening';

const AccountOpeningPage = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return(
    <>
      <Helmet>
        <title>Zulhilmi Zolkfli: MPlus Online CDS Account Opening</title>
        <meta name="description" content="Open and register a trading and CDS account with MPlus Online (Malacca Securities), and get free lifetime access to Ringgit Lab Elite to maximize your profit in stock trading." />
        <meta itemProp="name" content="Zulhilmi Zolkfli: MPlus Online CDS Account Opening" />
        <meta itemProp="description" content="Open and register a trading and CDS account with MPlus Online (Malacca Securities), and get free lifetime access to Ringgit Lab Elite to maximize your profit in stock trading." />
        <meta itemProp="image" content="https://www.zulhilmizolkfli.com/elite-mplus-macbook-imac.jpg" />
        <link rel="canonical" href="https://www.zulhilmizolkfli.com/mplus-online-cds-registration" />

        {/* Facebook Meta Tags */}
        <meta property="og:url" content="https://www.zulhilmizolkfli.com/mplus-online-cds-registration" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Zulhilmi Zolkfli: MPlus Online CDS Account Opening" />
        <meta property="og:description" content="Open and register a trading and CDS account with MPlus Online (Malacca Securities), and get free lifetime access to Ringgit Lab Elite to maximize your profit in stock trading." />
        <meta property="og:image" content="https://www.zulhilmizolkfli.com/elite-mplus-macbook-imac.jpg" />
        <meta property="og:image:alt" content="Zulhilmi Zolkfli" />
        <meta property="og:site_name" content="Zulhilmi Zolkfli" />

        {/* Twitter Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Zulhilmi Zolkfli: MPlus Online CDS Account Opening" />
        <meta name="twitter:description" content="Open and register a trading and CDS account with MPlus Online (Malacca Securities), and get free lifetime access to Ringgit Lab Elite to maximize your profit in stock trading." />
        <meta name="twitter:image" content="https://www.zulhilmizolkfli.com/elite-mplus-macbook-imac.jpg" />
        <meta name="twitter:image:alt" content="Zulhilmi Zolkfli" />
        <meta name="twitter:site" content="@zulhilmizolkfli" />
        <meta name="twitter:creator" content="@zulhilmizolkfli" />
      </Helmet>
      <Header />
      <AccountOpening />
      <Footer />
    </>
  )
}

export default AccountOpeningPage;