import React from 'react';
import { Button, Col, Container, Row } from 'reactstrap';
import Slide from 'react-reveal/Slide';
import Fade from 'react-reveal/Fade';
import EliteImg from '../../assets/elite-macbook.png';
import MPlusImg from '../../assets/mplus-macbook.png';
import TelegramSupportImg from '../../assets/telegram-support-macbook.png';

const CareerWhyUs = () => {

  return(
    <div style={{ backgroundColor: '#fafafa' }}>
      <Container className="pt-5 pb-5">
        <Slide bottom>
          <h2 className="text-center font-weight-bold pt-5">
            Why should you be a part of our team?
          </h2>
          <h3 className="mt-3 pb-4 grey-color text-center" style={{ fontSize: '24px' }}>
            Work with a reliable stockbroker and highly experienced in delivering the professional service
          </h3>
        </Slide>
        <Fade>
          <Row className="mt-5">
            <Col md={3} sm={12} xs={12} className="mb-5">
              <img alt="Ringgit Lab Elite: MPlus Online" src={MPlusImg} style={{ width: '100%' }} />
            </Col>
            <Col md={9} sm={12} xs={12} className="mb-5 align-self-center">
              <h4 className="font-weight-bold" style={{ fontSize: '24px' }}>
                MPlus Online
              </h4>
              <h5 className="grey-color">
                Your clients will trade stocks with the most reliable trading platform by MPlus Online. MPlus Online offers one of the cheapest brokerage fee 
                in Malaysia, which is 0.08% or min RM 8.
              </h5>
            </Col>
            <Col md={3} sm={12} xs={12} className="mb-5">
              <img alt="Ringgit Lab Elite: MPlus Online" src={EliteImg} style={{ width: '100%' }} />
            </Col>
            <Col md={9} sm={12} xs={12} className="mb-5 align-self-center">
              <h4 className="font-weight-bold" style={{ fontSize: '24px' }}>
                Ringgit Lab Elite
              </h4>
              <h5 className="grey-color">
                Your clients will get free access to Ringgit Lab Elite, an exclusive platform by Ringgit Lab. Ringgit Lab Elite offers free learning materials, 
                paper trade, trading journal, stock screener, market news and other useful features to come soon.
              </h5>
            </Col>
            <Col md={3} sm={12} xs={12} className="mb-5">
              <img alt="Ringgit Lab Elite: MPlus Online" src={TelegramSupportImg} style={{ width: '100%' }} />
            </Col>
            <Col md={9} sm={12} xs={12} className="mb-5 align-self-center">
              <h4 className="font-weight-bold" style={{ fontSize: '24px' }}>
                Telegram Support Group
              </h4>
              <h5 className="grey-color">
                Your clients will get daily notifications about latest stock market news and watch list recommendations. This group will be our main platform for 
                discussion and interaction. 
              </h5>
            </Col>
          </Row>
          <div className="mt-5 text-center">
            <a href="https://api.whatsapp.com/send?phone=60194902302" rel="noopener noreferrer" target="_blank">
              <Button className="primary-btn header-pill font-weight-bold">Contact for more details</Button>
            </a>
          </div>
        </Fade>
      </Container>
    </div>
  )
}

export default CareerWhyUs;