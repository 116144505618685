import React, { useState } from 'react';
import { Container, Alert, Button, Row, Col } from 'reactstrap';
import { HashLink } from 'react-router-hash-link';
import MPlusImg from '../../assets/mplus.png';
import ICSample from '../../assets/guides/ic-sample.png';
import CdsImg1 from '../../assets/guides/guide-1-sign-up.png';
import CdsImg2 from '../../assets/guides/guide-2-email.png';
import CdsImg3 from '../../assets/guides/guide-3-verify-email.png';
import CdsImg4 from '../../assets/guides/guide-4-email-verified.png';
import CdsImg5 from '../../assets/guides/guide-5-dr-code.png';
import CdsImg6 from '../../assets/guides/guide-6-personal.png';
import CdsImg7 from '../../assets/guides/guide-7-address.png';
import CdsImg8 from '../../assets/guides/guide-8-employment.png';
import CdsImg9 from '../../assets/guides/guide-9-spouse.png';
import CdsImg10 from '../../assets/guides/guide-10-financial.png';
import CdsImg11 from '../../assets/guides/guide-11-risk.png';
import CdsImg12 from '../../assets/guides/guide-12-declaration.png';
import CdsImg13 from '../../assets/guides/guide-13-summary.png';
import CdsImg14 from '../../assets/guides/guide-14-sign.png';
import CdsImg15 from '../../assets/guides/guide-15-declarations.png';
import CdsImg16 from '../../assets/guides/guide-16-payment.png';
import CdsImg17 from '../../assets/guides/guide-17-verify-identity.png';
import CdsImg18 from '../../assets/guides/guide-18-select-verify.png';
import CdsImg19 from '../../assets/guides/guide-19-verify-ic.png';
import CdsImg20 from '../../assets/guides/guide-20-choose-device.png';
import CdsImg21 from '../../assets/guides/guide-21-phone-upload-ic.png';
import CdsImg22 from '../../assets/guides/guide-22-phone-ic-sample.png';
import CdsImg23 from '../../assets/guides/guide-23-phone-start-selfie.png';
import CdsImg24 from '../../assets/guides/guide-24-phone-done.png';
import CdsImg25 from '../../assets/guides/guide-25-application-completed.png';
import CdsResumeImg1 from '../../assets/guides/guide-resume.png';
import CdsResumeImg2 from '../../assets/guides/guide-resume-email.png';
import CdsResumeImg3 from '../../assets/guides/guide-resume-email-button.png';
import { BankStatementSampleModal } from './BankStatementSampleModal';

const AccountOpening = () => {
  const [openModalExample, setOpenModalExample] = useState(false);

  const toggleModalExample = () => {
    setOpenModalExample(!openModalExample);
  };

  return(
    <>
      <Container className="pt-5 pb-5">
        <h1 className="text-center pt-5 mb-3 font-weight-bold" style={{ fontSize: '32px' }}>
          Open MPlus Online CDS account
        </h1>
        <Alert color="info" className="font-weight-bold mt-3">
          If you have any inquiries about MPlus CDS account or need further confirmation before the account opening, please reach out to 019-4902302 or email to contact@zulhilmizolkfli.com.
        </Alert>
        <Alert color="success" className="font-weight-bold mt-3">
          If you would like to open CDS account for company, please reach out to 019-4902302 or email to contact@zulhilmizolkfli.com.
        </Alert>
        <img alt="Ringgit Lab: MPlus Malacca Securities" src={MPlusImg} style={{ width: '100%' }} />
        <div className="mt-5 text-center">
          <a href="https://registration-v2.mplusonline.com/#/?drCode=R418" className="mb-3" rel="noopener noreferrer" target="_blank">
            <h3 className="mb-0">
              <Button className="primary-btn font-weight-bold" style={{ fontSize: '20px' }}>Proceed to MPlus Online</Button>
            </h3>
          </a>
        </div>
        <h2 className="font-weight-bold pt-5 mb-4" style={{ fontSize: '28px' }}>Steps to register a CDS account with MPlus Online</h2>
        <h3 style={{ fontSize: '18px' }} className="grey-color mt-4">Table Of Contents</h3>
        <ol type="i" className="mt-4">
          <li style={{ fontSize: '18px' }}>
            <h4 style={{ fontSize: '18px' }}>
              <HashLink className="link-text" to="/mplus-online-cds-registration#documents">
                Required documents to open MPlus CDS account
              </HashLink>
            </h4>
          </li>
          <li style={{ fontSize: '18px' }}>
            <h4 style={{ fontSize: '18px' }}>
              <HashLink className="link-text" to="/mplus-online-cds-registration#link">
                MPlus Online registration link
              </HashLink>
            </h4>
          </li>
          <li style={{ fontSize: '18px' }}>
            <h4 style={{ fontSize: '18px' }}>
              <HashLink className="link-text" to="/mplus-online-cds-registration#steps">
                Registration steps to open MPlus Online account
              </HashLink>
            </h4>
          </li>
          <li style={{ fontSize: '18px' }}>
            <h4 style={{ fontSize: '18px' }}>
              <HashLink className="link-text" to="/mplus-online-cds-registration#resume">
                Resume MPlus CDS account registration
              </HashLink>
            </h4>
          </li>
        </ol>
        <div className="divider mt-5"></div>
        <h2 style={{ fontSize: "24px", textDecoration: 'underline' }} className="pt-5 font-weight-bold" id="documents">
          i. Required documents to open MPlus CDS account
        </h2>
        <h3 className="mt-5" style={{ fontSize: '20px' }}>
          Please prepare the documents below to make the CDS account registration with MPlus Online process smoother.
        </h3>
        <ul>
          <li className="mt-3">
            <h4 style={{ fontSize: '20px' }}>
              Identity Card (NRIC) or passport.
            </h4>
          </li>
          <li className="mt-3">
            <h4 style={{ fontSize: '20px' }}>
              Bank statement.<br/>
              <span style={{ cursor: 'pointer' }} className="link-text" onClick={toggleModalExample}>Click here for example on how to screenshot your bank statement from the online banking website.</span>
            </h4>
          </li>
        </ul>
        <h2 style={{ fontSize: "24px", textDecoration: 'underline' }} className="pt-5 font-weight-bold" id="link">
          ii. MPlus Online registration link
        </h2>
        <h3 className="mt-5" style={{ fontSize: '20px' }}>
          Click on the link below to proceed with CDS account opening with MPlus Online.
        </h3>
        <h4 style={{ fontSize: '20px' }}>
          <a href="https://registration-v2.mplusonline.com/#/?drCode=R418" className="link-text" rel="noopener noreferrer" target="_blank">
            https://registration-v2.mplusonline.com
          </a>
        </h4>
        <h2 style={{ fontSize: "24px", textDecoration: 'underline' }} className="pt-5 font-weight-bold" id="steps">
          iii. Registration steps to open MPlus Online account
        </h2>
        <h3 className="mt-5" style={{ fontSize: '20px' }}>
          1. Provide your full name and email address to get started as shown in the example below. Then click Submit.
        </h3>
        <Row className="mt-4">
          <Col md={8} sm={12} xs={12}>
            <img src={CdsImg1} alt="Open MPlus Online CDS account Ringgit Lab Elite" style={{ width: '100%', boxShadow: 'rgba(0, 0, 0, 0.06) 0px 1rem 1rem', borderRadius: '10px' }} />
          </Col>
          <Col md={4} sm={12} xs={12}></Col>
        </Row>
        <h3 className="mt-5" style={{ fontSize: '20px' }}>
          2. Open your email to verify your email.
        </h3>
        <Row className="mt-4">
          <Col md={8} sm={12} xs={12}>
            <img src={CdsImg2} alt="Open MPlus Online CDS account Ringgit Lab Elite" style={{ width: '100%', boxShadow: 'rgba(0, 0, 0, 0.06) 0px 1rem 1rem', borderRadius: '10px' }} />
          </Col>
          <Col md={4} sm={12} xs={12}></Col>
          <Col md={8} sm={12} xs={12} className="mt-5">
            <img src={CdsImg3} alt="Open MPlus Online CDS account Ringgit Lab Elite" style={{ width: '100%', boxShadow: 'rgba(0, 0, 0, 0.06) 0px 1rem 1rem', borderRadius: '10px' }} />
          </Col>
          <Col md={4} sm={12} xs={12}></Col>
        </Row>
        <h3 className="mt-5" style={{ fontSize: '20px' }}>
          3. You'll be redirected to a new browser. Click Continue to proceed with next steps of the registration.
        </h3>
        <Row className="mt-4">
          <Col md={8} sm={12} xs={12}>
            <img src={CdsImg4} alt="Open MPlus Online CDS account Ringgit Lab Elite" style={{ width: '100%', boxShadow: 'rgba(0, 0, 0, 0.06) 0px 1rem 1rem', borderRadius: '10px' }} />
          </Col>
          <Col md={4} sm={12} xs={12}></Col>
        </Row>
        <h3 className="mt-5" style={{ fontSize: '20px' }}>
          <span className="danger font-weight-bold">IMPORTANT STEP!</span><br/>
          4. Fill the details accordingly.<strong> Please fill the DR Code with <span className="danger">R418.</span></strong>
        </h3>
        <Row className="mt-4">
          <Col md={8} sm={12} xs={12}>
            <Alert color="info" className="mt-4">
              <h4 className="mt-2" style={{ fontSize: '16px' }}>
                <strong>Do you have CDS account on Bursa Anywhere?</strong><br/>
                If you have never registered a CDS account from Bursa Anywhere mobile app, please select No.
              </h4>
              <h4 className="mt-3" style={{ fontSize: '16px' }}>
                <strong>Account type</strong><br/>
                If you would like to just trade the syariah-compliant stocks, please select Islamic Cash Upfront Account.
              </h4>
              <h4 className="mt-3" style={{ fontSize: '16px' }}>
                <strong>Username</strong><br/>
                Write your preferable username to use when you login to MPlus Online.
              </h4>
              <h4 className="mt-3" style={{ fontSize: '16px' }}>
                <strong>Do you have a preferred Dealer's Representative or Remisier?</strong><br/>
                Select Yes.
              </h4>
              <h4 className="mt-3" style={{ fontSize: '16px' }}>
                <strong>DR code</strong><br/>
                Write R418.
              </h4>
              <h4 className="mt-3" style={{ fontSize: '16px' }}>
                <strong>MR code</strong><br/>
                Leave this space blank. If you were provided with a MR code by a Marketing Representative from Ringgit Lab Elite, please write here.
              </h4>
            </Alert>
            <img src={CdsImg5} alt="Open MPlus Online CDS account Ringgit Lab Elite" style={{ width: '100%', boxShadow: 'rgba(0, 0, 0, 0.06) 0px 1rem 1rem', borderRadius: '10px' }} />
          </Col>
          <Col md={4} sm={12} xs={12}></Col>
        </Row>
        <h3 className="mt-5" style={{ fontSize: '20px' }}>
          5. Provide your personal details. Make sure you with your full name as per IC.
        </h3>
        <Row className="mt-4">
          <Col md={8} sm={12} xs={12}>
            <img src={CdsImg6} alt="Open MPlus Online CDS account Ringgit Lab Elite" style={{ width: '100%', boxShadow: 'rgba(0, 0, 0, 0.06) 0px 1rem 1rem', borderRadius: '10px' }} />
          </Col>
          <Col md={4} sm={12} xs={12}></Col>
        </Row>
        <h3 className="mt-5" style={{ fontSize: '20px' }}>
          6. Write your address as written on your IC. Please fill the address following the address line on your IC.
        </h3>
        <Row className="mt-4">
          <Col md={8} sm={12} xs={12}>
            <Alert color="info" className="mt-4 mb-3">
              <img src={ICSample} alt="Open MPlus Online CDS account Ringgit Lab Elite" style={{ width: '100%', boxShadow: 'rgba(0, 0, 0, 0.06) 0px 1rem 1rem', borderRadius: '10px' }} />
            </Alert>
            <img src={CdsImg7} alt="Open MPlus Online CDS account Ringgit Lab Elite" style={{ width: '100%', boxShadow: 'rgba(0, 0, 0, 0.06) 0px 1rem 1rem', borderRadius: '10px' }} />
          </Col>
          <Col md={4} sm={12} xs={12}></Col>
        </Row>
        <h3 className="mt-5" style={{ fontSize: '20px' }}>
          7. Fill the details of your employment. 
        </h3>
        <Row className="mt-4">
          <Col md={8} sm={12} xs={12}>
            <img src={CdsImg8} alt="Open MPlus Online CDS account Ringgit Lab Elite" style={{ width: '100%', boxShadow: 'rgba(0, 0, 0, 0.06) 0px 1rem 1rem', borderRadius: '10px' }} />
          </Col>
          <Col md={4} sm={12} xs={12}></Col>
        </Row>
        <h3 className="mt-5" style={{ fontSize: '20px' }}>
          8. Then provide your spouse details if you're married.
        </h3>
        <Row className="mt-4">
          <Col md={8} sm={12} xs={12}>
            <img src={CdsImg9} alt="Open MPlus Online CDS account Ringgit Lab Elite" style={{ width: '100%', boxShadow: 'rgba(0, 0, 0, 0.06) 0px 1rem 1rem', borderRadius: '10px' }} />
          </Col>
          <Col md={4} sm={12} xs={12}></Col>
        </Row>
        <h3 className="mt-5" style={{ fontSize: '20px' }}>
          9. Next, provide the information of a bank you would like to use to deposit and withdraw from your CDS account.
        </h3>
        <Row className="mt-4">
          <Col md={8} sm={12} xs={12}>
            <Alert color="info" className="mt-4">
              <h4 className="mt-2" style={{ fontSize: '16px' }}>
                <strong>Are you using an Islamic Banking account?</strong><br/>
                Check your bank statement if it is islamic account.
              </h4>
              <h4 className="mt-3" style={{ fontSize: '16px' }}>
                <strong>Bank statement</strong><br/>
                Upload your bank statement. Make sure to crop your bank statement where only stated the following details.<br/><br/>
                a) Bank logo<br/>
                b) Name of the bank<br/>
                c) Full name as per IC<br/>
                d) Account number
              </h4>
            </Alert>
            <img src={CdsImg10} alt="Open MPlus Online CDS account Ringgit Lab Elite" style={{ width: '100%', boxShadow: 'rgba(0, 0, 0, 0.06) 0px 1rem 1rem', borderRadius: '10px' }} />
          </Col>
          <Col md={4} sm={12} xs={12}></Col>
        </Row>
        <h3 className="mt-5" style={{ fontSize: '20px' }}>
          10. Answer all questions about your investment risk.
        </h3>
        <Row className="mt-4">
          <Col md={8} sm={12} xs={12}>
            <img src={CdsImg11} alt="Open MPlus Online CDS account Ringgit Lab Elite" style={{ width: '100%', boxShadow: 'rgba(0, 0, 0, 0.06) 0px 1rem 1rem', borderRadius: '10px' }} />
          </Col>
          <Col md={4} sm={12} xs={12}></Col>
        </Row>
        <h3 className="mt-5" style={{ fontSize: '20px' }}>
          11. Then, complete the declarations section accordingly.
        </h3>
        <Row className="mt-4">
          <Col md={8} sm={12} xs={12}>
            <img src={CdsImg12} alt="Open MPlus Online CDS account Ringgit Lab Elite" style={{ width: '100%', boxShadow: 'rgba(0, 0, 0, 0.06) 0px 1rem 1rem', borderRadius: '10px' }} />
          </Col>
          <Col md={4} sm={12} xs={12}></Col>
        </Row>
        <h3 className="mt-5" style={{ fontSize: '20px' }}>
          12. Make sure the <strong>DR code is <span className="danger">R418</span>.</strong> Then, please confirm all the provided details are correct.
        </h3>
        <Row className="mt-4">
          <Col md={8} sm={12} xs={12}>
            <img src={CdsImg13} alt="Open MPlus Online CDS account Ringgit Lab Elite" style={{ width: '100%', boxShadow: 'rgba(0, 0, 0, 0.06) 0px 1rem 1rem', borderRadius: '10px' }} />
          </Col>
          <Col md={4} sm={12} xs={12}></Col>
        </Row>
        <h3 className="mt-5" style={{ fontSize: '20px' }}>
          13. Double check your bank account details. Then, write your signature and click Save. Please be noted that the signature will be used for any application related to your trading account in the future.<br/><br/>
          If you have confirmed about all details, click the Proceed to Payment button.
        </h3>
        <Row className="mt-4">
          <Col md={8} sm={12} xs={12}>
            <img src={CdsImg14} alt="Open MPlus Online CDS account Ringgit Lab Elite" style={{ width: '100%', boxShadow: 'rgba(0, 0, 0, 0.06) 0px 1rem 1rem', borderRadius: '10px' }} />
          </Col>
          <Col md={4} sm={12} xs={12}></Col>
        </Row>
        <h3 className="mt-5" style={{ fontSize: '20px' }}>
          14. Carefully read all declarations and tick the box to proceed.
        </h3>
        <Row className="mt-4">
          <Col md={8} sm={12} xs={12}>
            <img src={CdsImg15} alt="Open MPlus Online CDS account Ringgit Lab Elite" style={{ width: '100%', boxShadow: 'rgba(0, 0, 0, 0.06) 0px 1rem 1rem', borderRadius: '10px' }} />
          </Col>
          <Col md={4} sm={12} xs={12}></Col>
        </Row>
        <h3 className="mt-5" style={{ fontSize: '20px' }}>
          15. Next, you'll be redirected to the payment page. Choose an online banking to proceed with the registration fee of RM 11 using FPX online system.
        </h3>
        <Row className="mt-4">
          <Col md={8} sm={12} xs={12}>
            <Alert color="info" className="mt-4">
              <h4 className="mt-2" style={{ fontSize: '16px' }}>
                Make sure to pay the registration fee <strong>using your own account.</strong> Third party account is not allowed.
              </h4>
            </Alert>
            <img src={CdsImg16} alt="Open MPlus Online CDS account Ringgit Lab Elite" style={{ width: '100%', boxShadow: 'rgba(0, 0, 0, 0.06) 0px 1rem 1rem', borderRadius: '10px' }} />
          </Col>
          <Col md={4} sm={12} xs={12}></Col>
        </Row>
        <h3 className="mt-5" style={{ fontSize: '20px' }}>
          16. After you've completed the payment for registration fee, return to the registration page. Then, proceed with identity verification step.
        </h3>
        <Row className="mt-4">
          <Col md={8} sm={12} xs={12}>
            <img src={CdsImg17} alt="Open MPlus Online CDS account Ringgit Lab Elite" style={{ width: '100%', boxShadow: 'rgba(0, 0, 0, 0.06) 0px 1rem 1rem', borderRadius: '10px' }} />
          </Col>
          <Col md={4} sm={12} xs={12}></Col>
        </Row>
        <h3 className="mt-5" style={{ fontSize: '20px' }}>
          17. Select Malaysia if you're verifying your identity using your NRIC. For foreigner, please choose your origin country.
        </h3>
        <Row className="mt-4">
          <Col md={8} sm={12} xs={12}>
            <img src={CdsImg18} alt="Open MPlus Online CDS account Ringgit Lab Elite" style={{ width: '100%', boxShadow: 'rgba(0, 0, 0, 0.06) 0px 1rem 1rem', borderRadius: '10px' }} />
          </Col>
          <Col md={4} sm={12} xs={12}></Col>
        </Row>
        <h3 className="mt-5" style={{ fontSize: '20px' }}>
          18. If you're using laptop or computer during the registration, you're advised to click the <strong>Continue On Another Device</strong> button to use your phone for the next steps. 
          <strong> Please don't close the browser until you complete the self identity verification on your phone.</strong>
        </h3>
        <Row className="mt-4">
          <Col md={8} sm={12} xs={12}>
            <img src={CdsImg19} alt="Open MPlus Online CDS account Ringgit Lab Elite" style={{ width: '100%', boxShadow: 'rgba(0, 0, 0, 0.06) 0px 1rem 1rem', borderRadius: '10px' }} />
          </Col>
          <Col md={4} sm={12} xs={12}></Col>
        </Row>
        <h3 className="mt-5" style={{ fontSize: '20px' }}>
          19. Choose one method. The easiest way is the <strong>Scan QR code</strong> method. Use your phone's camera to scan the QR code.
        </h3>
        <Row className="mt-4">
          <Col md={8} sm={12} xs={12}>
            <img src={CdsImg20} alt="Open MPlus Online CDS account Ringgit Lab Elite" style={{ width: '100%', boxShadow: 'rgba(0, 0, 0, 0.06) 0px 1rem 1rem', borderRadius: '10px' }} />
          </Col>
          <Col md={4} sm={12} xs={12}></Col>
        </Row>
        <h3 className="mt-5" style={{ fontSize: '20px' }}>
          20. Take a photo or upload the <strong>front and back</strong> of your NRIC.<br/><br/>
          For foreigners, please upload the front page of your passport and your visa details.
        </h3>
        <Row className="mt-4">
          <Col md={8} sm={12} xs={12}>
            <img src={CdsImg21} alt="Open MPlus Online CDS account Ringgit Lab Elite" style={{ width: '100%', boxShadow: 'rgba(0, 0, 0, 0.06) 0px 1rem 1rem', borderRadius: '10px' }} />
          </Col>
          <Col md={4} sm={12} xs={12}></Col>
        </Row>
        <h3 className="mt-5" style={{ fontSize: '20px' }}>
          21. Make sure all details on your NRIC or passport are clear. <strong>No watermark are allowed on your NRIC or passport.</strong>
        </h3>
        <Row className="mt-4">
          <Col md={8} sm={12} xs={12}>
            <img src={CdsImg22} alt="Open MPlus Online CDS account Ringgit Lab Elite" style={{ width: '100%', boxShadow: 'rgba(0, 0, 0, 0.06) 0px 1rem 1rem', borderRadius: '10px' }} />
          </Col>
          <Col md={4} sm={12} xs={12}></Col>
        </Row>
        <h3 className="mt-5" style={{ fontSize: '20px' }}>
          22. Next, please allow the system to access your camera. Follow the instructions on your screen to verify your self identification.
        </h3>
        <Row className="mt-4">
          <Col md={8} sm={12} xs={12}>
            <img src={CdsImg23} alt="Open MPlus Online CDS account Ringgit Lab Elite" style={{ width: '100%', boxShadow: 'rgba(0, 0, 0, 0.06) 0px 1rem 1rem', borderRadius: '10px' }} />
          </Col>
          <Col md={4} sm={12} xs={12}></Col>
        </Row>
        <h3 className="mt-5" style={{ fontSize: '20px' }}>
          23. Congratulations! Your identity verification has completed. Once completed, please return to the previous device to complete the MPlus CDS account registration.
        </h3>
        <Row className="mt-4">
          <Col md={8} sm={12} xs={12}>
            <img src={CdsImg24} alt="Open MPlus Online CDS account Ringgit Lab Elite" style={{ width: '100%', boxShadow: 'rgba(0, 0, 0, 0.06) 0px 1rem 1rem', borderRadius: '10px' }} />
          </Col>
          <Col md={4} sm={12} xs={12}></Col>
        </Row>
        <h3 className="mt-5" style={{ fontSize: '20px' }}>
          24. Congratulations! You've completed the account opening with MPlus Online. Click Done to finish the registration.<br/><br/>
          Upon the completion of your CDS and trading account, you'll receive emails from support@mplusonline.com.my.
        </h3>
        <Row className="mt-4">
          <Col md={8} sm={12} xs={12}>
            <img src={CdsImg25} alt="Open MPlus Online CDS account Ringgit Lab Elite" style={{ width: '100%', boxShadow: 'rgba(0, 0, 0, 0.06) 0px 1rem 1rem', borderRadius: '10px' }} />
          </Col>
          <Col md={4} sm={12} xs={12}></Col>
        </Row>
        <h3 className="mt-5" style={{ fontSize: '20px' }}>
          Thank you for your time. The steps to open CDS account with MPlus Online end here. If you have further inquiries about MPlus Online or Ringgit Lab Elite, please reach out to our team at 019-4902302 or email to elite@ringgitlab.com.
        </h3>
        <h2 style={{ fontSize: "24px", textDecoration: 'underline' }} className="pt-5 font-weight-bold" id="resume">
          iv. Resume MPlus CDS account registration
        </h2>
        <h3 className="mt-5" style={{ fontSize: '20px' }}>
          1. Click on the link below to resume the application of CDS account opening with MPlus Online.
        </h3>
        <h4 style={{ fontSize: '20px' }}>
          <a href="https://registration-v2.mplusonline.com/#/?drCode=R418" className="link-text" rel="noopener noreferrer" target="_blank">
            https://registration-v2.mplusonline.com
          </a>
        </h4>
        <h3 className="mt-5" style={{ fontSize: '20px' }}>
          2. Insert the email address you've registered for the MPlus CDS account registration and click Resume.
        </h3>
        <Row className="mt-4">
          <Col md={8} sm={12} xs={12}>
            <img src={CdsResumeImg1} alt="Open MPlus Online CDS account Ringgit Lab Elite" style={{ width: '100%', boxShadow: 'rgba(0, 0, 0, 0.06) 0px 1rem 1rem', borderRadius: '10px' }} />
          </Col>
          <Col md={4} sm={12} xs={12}></Col>
        </Row>
        <h3 className="mt-5" style={{ fontSize: '20px' }}>
          3. Check your email. If you didn't receive any email from mPlus Online, please check your spam folder.
        </h3>
        <Row className="mt-4">
          <Col md={8} sm={12} xs={12}>
            <img src={CdsResumeImg2} alt="Open MPlus Online CDS account Ringgit Lab Elite" style={{ width: '100%', boxShadow: 'rgba(0, 0, 0, 0.06) 0px 1rem 1rem', borderRadius: '10px' }} />
          </Col>
          <Col md={4} sm={12} xs={12}></Col>
        </Row>
        <h3 className="mt-5" style={{ fontSize: '20px' }}>
          4. Then, click the <strong>Continue my registration</strong> button to resume your application.
        </h3>
        <Row className="mt-4">
          <Col md={8} sm={12} xs={12}>
            <img src={CdsResumeImg3} alt="Open MPlus Online CDS account Ringgit Lab Elite" style={{ width: '100%', boxShadow: 'rgba(0, 0, 0, 0.06) 0px 1rem 1rem', borderRadius: '10px' }} />
          </Col>
          <Col md={4} sm={12} xs={12}></Col>
        </Row>
      </Container>
      <BankStatementSampleModal 
        open={openModalExample}
        toggle={toggleModalExample}
      />
    </>
  )
}

export default AccountOpening;