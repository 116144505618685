import React from 'react';
import { Container, Modal, ModalBody, ModalHeader } from 'reactstrap';
import MaybankImg from '../../assets/guides/maybank-sample.png';
import CimbImg from '../../assets/guides/cimb-sample.png';

interface Props {
  open: boolean;
  toggle: () => void;
}

export const BankStatementSampleModal: React.FC<Props> = (props) => {

  return (
    <>
      <Modal isOpen={props.open} toggle={props.toggle} size="lg">
        <ModalHeader toggle={props.toggle}>
          <span style={{ fontSize: '22px' }} className="font-weight-bold pt-2 pb-2">
            Example of bank statement
          </span>
        </ModalHeader>
        <ModalBody  className="pb-5 mb-5">
          <Container className="pt-3 pb-5">
            <h3 style={{ fontSize: '18px' }} className="font-weight-bold mb-3">
              Please follow the format as shown in the example below. Bank statement must only contains:
            </h3>
            <h4 style={{ fontSize: '18px' }} className="grey-color mt-4">
              1. Bank name
            </h4>
            <h4 style={{ fontSize: '18px' }} className="grey-color mt-2">
              2. Logo of the bank
            </h4>
            <h4 style={{ fontSize: '18px' }} className="grey-color mt-2">
              3. Full name as per IC
            </h4>
            <h4 style={{ fontSize: '18px' }} className="grey-color mt-2">
              4. Account number
            </h4>
            <div className="mt-3">
              <img alt="Ringgit Lab Elite: MPlus Online CDS registration" src={MaybankImg} style={{ width: '100%', boxShadow: 'rgba(0, 0, 0, 0.06) 0px 1rem 1rem', borderRadius: '10px' }} />
              <p className="text-center mt-3">Sample of Maybank bank statement</p>
            </div>
            <div className="mt-5">
              <img alt="Ringgit Lab Elite: MPlus Online CDS registration" src={CimbImg} style={{ width: '100%', boxShadow: 'rgba(0, 0, 0, 0.06) 0px 1rem 1rem', borderRadius: '10px' }} />
              <p className="text-center mt-3">Sample of CIMB bank statement</p>
            </div>
          </Container>
        </ModalBody>
      </Modal>
    </>
  );
}