import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
} from 'reactstrap';
import Logo from '../../assets/zulhilmi-brand-logo.png';

const Header = () => {
  const [open, setOpen] = useState(false);

  const toggle = () => {
    setOpen(!open);
  };

  return(
    <>
      <Navbar className="fixed-navbar pt-3 pb-3" expand="md">
        <Link to="/" className="text-decoration-none" style={{ width: '200px' }}>
          <img src={Logo} alt="Ringgit Lab Logo" className="w-100" />
        </Link>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={open} navbar>
          <Nav className="mt-0 mr-auto" navbar>
            <NavItem className="toggle-space">
              <Link to="/about" className="nav-item-left" onClick={toggle}>
                About
              </Link>
            </NavItem>
            <NavItem className="toggle-space">
              <Link to="/careers" className="nav-item-left" onClick={toggle}>
                Careers
              </Link>
            </NavItem>
            <NavItem className="toggle-space">
              <Link to="/mplus-online" className="nav-item-left" onClick={toggle}>
                MPlus Online
              </Link>
            </NavItem>
            {/* <NavItem className="toggle-space">
              <Link to="/ipo-miti-application" className="nav-item-left" onClick={toggle}>
                IPO (MITI)
              </Link>
            </NavItem> */}
            <NavItem className="toggle-space">
              <a href="https://elite.ringgitlab.com/" className="nav-item-left" rel="noopener noreferrer" target="_blank" onClick={toggle}>
                Ringgit Lab Elite
              </a>
            </NavItem>
          </Nav>
          <Nav className="mt-0 ml-auto" navbar>
            <NavItem className="toggle-space">
              <Link to="/mplus-online-cds-registration" className="header-pill" onClick={toggle}>
                Open CDS account
              </Link>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    </>
  )
}

export default Header;