import React from 'react';
import { Button, Col, Container, Row } from 'reactstrap';
import FounderImg from '../../assets/zulhilmi.png';
import Fade from 'react-reveal/Fade';
import { Link } from 'react-router-dom';

const AboutMe = () => {
  return(
    <>
      <Container className="mt-5 mb-5">
        <Row className="pt-5">
          <Col md={3} sm={12} xs={12} className="pt-5 pb-5">
            <Fade>
              <img alt="Ringgit Lab Elite Founder" src={FounderImg} style={{ width: '100%' }} />
              <div className="mt-5 text-center">
                <Link to="/mplus-online-cds-registration">
                  <Button className="primary-btn header-pill font-weight-bold">Open CDS account</Button>
                </Link>
              </div>
            </Fade>
          </Col>
          <Col md={1} sm={12} xs={12}></Col>
          <Col md={8} sm={12} xs={12} className="pt-5 pb-5">
            <h1 className="font-weight-bold text-center text-md-left">
              About Zulhilmi Zolkfli
            </h1>
            <h2 className="mt-5 font-weight-bold">Summary</h2>
            <h3 className="mt-4 grey-color font-weight-bold" style={{ fontSize: '20px' }}>
              Name
            </h3>
            <h4 className="grey-color" style={{ fontSize: '20px' }}>
              Zulhilmi Zolkfli
            </h4>
            <h3 className="mt-4 grey-color font-weight-bold" style={{ fontSize: '20px' }}>
              Title
            </h3>
            <h4 className="grey-color" style={{ fontSize: '20px' }}>
              Licensed Stockbroker (Remisier)
            </h4>
            <h3 className="mt-4 grey-color font-weight-bold" style={{ fontSize: '20px' }}>
              Broker
            </h3>
            <h4 className="grey-color" style={{ fontSize: '20px' }}>
              Malacca Securities Sdn Bhd
            </h4>
            <h3 className="mt-4 grey-color font-weight-bold" style={{ fontSize: '20px' }}>
              Remisier / DR Code
            </h3>
            <h4 className="grey-color" style={{ fontSize: '20px' }}>
              R418
            </h4>
            <h3 className="mt-4 grey-color font-weight-bold" style={{ fontSize: '20px' }}>
              Contact Number
            </h3>
            <h4 className="grey-color" style={{ fontSize: '20px' }}>
              +60194902302
            </h4>
            <h3 className="mt-4 grey-color font-weight-bold" style={{ fontSize: '20px' }}>
              Email
            </h3>
            <h4 className="grey-color" style={{ fontSize: '20px' }}>
              contact@zulhilmizolkfli.com
            </h4>
            <h3 className="mt-4 grey-color font-weight-bold" style={{ fontSize: '20px' }}>
              CDS account opening URL
            </h3>
            <h4 className="grey-color" style={{ fontSize: '20px' }}>
              <a className="link-text" href="https://www.zulhilmizolkfli.com/mplus-online-cds-registration" rel="noopener noreferrer" target="_blank">
                https://www.zulhilmizolkfli.com/mplus-online-cds-registration
              </a>
            </h4>
            <h2 className="mt-5 font-weight-bold">Introduction</h2>
            <h3 className="mt-4 grey-color" style={{ fontSize: '20px' }}>
              Salam and hello to everyone,<br/>
              Thank you for visiting Zulhilmi Zolkfli Remisier MPlus website.<br/><br/>
              The profession of a stockbroker (remisier) has changed over the past years. Now that we have online trading platform, retail investors could trade stocks without calling their remisier to place an order anymore. 
              The financial industry evolves quickly since the era of internet, as well as the profession of remisier. As a remisier, I'm thriving to give the best and professional service to cater my clients' needs.<br/><br/> 
              As an effort to deliver one-stop centre as well to give competitive and special service to my clients, I've developed a platform named Ringgit Lab Elite. Ringgit Lab Elite offers free learning materials, paper trade, trading journal, stock screener, market news and other useful features to come soon.<br/><br/>
              My background and expertise in technology industry in Japan for more than 4 years clearly would help me to serve the stock market industry in Malaysia. The experience working
              at Japanese companies also taught me on how to deliver the best customer and management service.<br/><br/>
              My mission as a remisier is to become one of the most reliable remisier in Malaysia to serve the needs of the market. I will continuously improve my service through Ringgit Lab Elite 
              and actively participate in organizations to contribute to our stock market and financial industry.<br/><br/>
              Please don't be hesitate to reach out to me should you need further assistance to kick-off your stock trading.<br/><br/>
              Also, please share the link below to help me reach out to your circles about investing in stock market. Thank you and I'm looking forward to serve you soon.<br/><br/>
              <a className="link-text" href="https://www.zulhilmizolkfli.com/mplus-online-cds-registration" rel="noopener noreferrer" target="_blank">
                https://www.zulhilmizolkfli.com/mplus-online-cds-registration
              </a>
            </h3>
          </Col>
        </Row>
      </Container>
    </>
  )
}
export default AboutMe;