import React from 'react';
import { Button, Container } from 'reactstrap';
import Slide from 'react-reveal/Slide';
import Fade from 'react-reveal/Fade';
import FPMRImg from '../../assets/fpmr-sidc.png';

const CareerSteps = () => {

  return(
    <>
      <Container className="pt-5 pb-5">
        <Slide bottom>
          <h2 className="text-center font-weight-bold pt-5">
            What should you do to become a Marketing Representative?
          </h2>
          <h3 className="mt-3 pb-4 grey-color text-center" style={{ fontSize: '24px' }}>
            Follow these simple steps to learn how to kick-off your career as a Marketing Representative
          </h3>
          <div>
            <img alt="Ringgit Lab Elite: MPlus Online" src={FPMRImg} style={{ width: '100%' }} />
          </div>
          <ol type="1">
            <li style={{ fontSize: '20px' }} className="font-weight-bold mt-5">
              <h4 style={{ fontSize: '20px' }}>
                <span className="font-weight-bold">Create an account on SIDC platform</span><br/>
                You need to register an account before you can book for a{' '}
                <span>
                  <a href="https://www.sidc.com.my/learn/efpmr/#" rel="noopener noreferrer" target="_blank" className="link-text">Familiarisation Programme For Marketing Representative</a>
                </span>. Click{' '}
                <span>
                  <a href="https://erp.sidc.com.my/web/signup" rel="noopener noreferrer" target="_blank" className="link-text">here</a>
                </span> to sign up an account.
              </h4>
            </li>
            <li style={{ fontSize: '20px' }} className="font-weight-bold mt-5">
              <h4 style={{ fontSize: '20px' }}>
                <span className="font-weight-bold">Reserve a date to complete the Familiarisation Programme assessment</span><br/>
                You'll be given 2 months to access the familiarisation programme before you have to complete simple assessment on the date and time you reserve.
              </h4>
            </li>
            <li style={{ fontSize: '20px' }} className="font-weight-bold mt-5">
              <h4 style={{ fontSize: '20px' }}>
                <span className="font-weight-bold">Check assessment result</span><br/>
                After 3 working days, you may check the result on SIDC platform. If you pass the assessment, you must download the online certificate.
              </h4>
            </li>
            <li style={{ fontSize: '20px' }} className="font-weight-bold mt-5">
              <h4 style={{ fontSize: '20px' }}>
                <span className="font-weight-bold">Contact me to apply for license and further details</span><br/>
                Reach out to me by clicking the button below. I will guide you on how to apply for license and kick-off your career as a Marketing Representative.
              </h4>
            </li>
          </ol>
        </Slide>
        <Fade>
          <div className="pt-5 text-center">
            <a href="https://api.whatsapp.com/send?phone=60194902302" rel="noopener noreferrer" target="_blank">
              <Button className="primary-btn header-pill font-weight-bold">Contact for more details</Button>
            </a>
          </div>
        </Fade>
      </Container>
    </>
  )
}

export default CareerSteps;