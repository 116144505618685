import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faLinkedin, faWhatsapp, faMedium } from '@fortawesome/free-brands-svg-icons';

const FooterSNS = () => {
  return(
    <ul className="social-icon">
      {/* <li>
        <a className="facebook" href="https://www.facebook.com/ringgitlabelite" rel="noopener noreferrer" target="_blank"><FontAwesomeIcon icon={ faFacebookF } /></a>
      </li>
      <li>
        <a className="instagram" href="https://www.instagram.com/ringgitlabelite/" rel="noopener noreferrer" target="_blank"><FontAwesomeIcon icon={ faInstagram } /></a>
      </li>
      <li>
        <a className="medium" href="https://ringgitlabelite.medium.com/" rel="noopener noreferrer" target="_blank"><FontAwesomeIcon icon={ faMedium } /></a>
      </li>
      <li>
        <a className="youtube" href="https://www.youtube.com/channel/UCwNZGWGSSQbpiG__W8UrPrg" rel="noopener noreferrer" target="_blank"><FontAwesomeIcon icon={ faYoutube } /></a>
      </li>
      <li>
        <a className="tiktok" href="https://www.tiktok.com/@ringgitlabelite" rel="noopener noreferrer" target="_blank"><FontAwesomeIcon icon={ faTiktok } /></a>
      </li> */}
      <li>
        <a className="whatsapp" href="https://api.whatsapp.com/send?phone=60194902302" rel="noopener noreferrer" target="_blank"><FontAwesomeIcon icon={ faWhatsapp } /></a>
      </li>
      <li>
        <a className="twitter" href="https://twitter.com/zulhilmizolkfli" rel="noopener noreferrer" target="_blank"><FontAwesomeIcon icon={ faTwitter } /></a>
      </li>
      <li>
        <a className="linkedin" href="https://www.linkedin.com/in/zulhilmizolkfli/" rel="noopener noreferrer" target="_blank"><FontAwesomeIcon icon={ faLinkedin } /></a>
      </li>
      <li>
        <a className="medium" href="https://zulhilmizolkfli.medium.com/" rel="noopener noreferrer" target="_blank"><FontAwesomeIcon icon={ faMedium } /></a>
      </li>
    </ul>
  );
};

export default FooterSNS;
