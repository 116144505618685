import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Button, Row, Col } from 'reactstrap';
import MPlusImg from '../../assets/mplus.png';
import MPlusPlatformImg from '../../assets/mplus-platform.png';
import Slide from 'react-reveal/Slide';
import Fade from 'react-reveal/Fade';

const MPlus = () => {
  return(
    <>
      <Container className="pt-5 pb-5">
        <Slide bottom>
          <h1 className="text-center mt-5 mb-3 font-weight-bold">
            MPlus - Equipping Traders For The Win
          </h1>
          <div className="mt-5 mb-5 text-center">
            <Button
              className="info-btn font-weight-bold"
              href="https://mplusonline.com/"
              rel="noopener noreferrer" target="_blank"
              style={{ borderRadius: '20px' }}
            >
              Visit MPlus website
            </Button>
          </div>
          <img alt="Ringgit Lab: MPlus Malacca Securities" src={MPlusImg} style={{ width: '100%', boxShadow: 'rgba(0, 0, 0, 0.06) 0px 1rem 1rem', borderRadius: '20px' }} />
        </Slide>
        <div className="divider mt-5"></div>
        <Fade bottom>
          <h2 className="text-center font-weight-bold mt-5 mb-5">
            Why choose MPlus as your broker?
          </h2>
          <img alt="Ringgit Lab: MPlus Malacca Securities" src={MPlusPlatformImg} style={{ width: '100%' }} />
          <Row className="mt-5">
            <Col md={4} sm={12} xs={12} className="mb-5">
              <h3 className="text-center font-weight-bold" style={{ fontSize: '22px' }}>
                The cheapest brokerage fee
              </h3>
              <h4 className="text-center grey-color" style={{ fontSize: '20px' }}>
                MPlus offers among the cheapest brokerage fee (0.08%) in the market with a minimum of RM 8.
              </h4>
            </Col>
            <Col md={4} sm={12} xs={12} className="mb-5">
              <h3 className="text-center font-weight-bold" style={{ fontSize: '22px' }}>
                Intuitive trading platform
              </h3>
              <h4 className="text-center grey-color" style={{ fontSize: '20px' }}>
                MPlus platform allows you to trade easily while equipping you with fundamental and technical analysis.
              </h4>
            </Col>
            <Col md={4} sm={12} xs={12} className="mb-5">
              <h3 className="text-center font-weight-bold" style={{ fontSize: '22px' }}>
                Full support group
              </h3>
              <h4 className="text-center grey-color" style={{ fontSize: '20px' }}>
                MPlus offers a great customer service. You'll also receive invitation to our support groups on Telegram.
              </h4>
            </Col>
          </Row>
          <div className="mt-3 text-center">
            <Link to="/mplus-online-cds-registration">
              <h3 className="mb-0">
                <Button className="primary-btn header-pill font-weight-bold">Open CDS account</Button>
              </h3>
            </Link>
          </div>
          <div className="divider mt-5"></div>
          <h3 className="mt-5 text-center font-weight-bold" style={{ fontSize: '22px' }}>
            We'll update on how to use MPlus platform soon. We want you to be familiarized so that you'll get prepared to trade stocks smoothly.
          </h3>
        </Fade>
      </Container>
    </>
  )
}

export default MPlus;