import React, { useEffect } from 'react';
import Header from '../header/Header';
import Footer from '../footer/Footer';
import { Helmet } from 'react-helmet';
import IpoMiti from './IpoMiti';

const IpoMitiPage = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return(
    <>
      <Helmet>
        <title>Apply IPO through MITI with Margin Financing</title>
        <meta name="description" content="Apply IPO through MITI and get margin financing up to 70%. This offer only applicable for Bumiputera and CDS account registered with Zulhilmi Zolkfli." />
        <meta itemProp="name" content="Apply IPO through MITI with Margin Financing" />
        <meta itemProp="description" content="Apply IPO through MITI and get margin financing up to 70%. This offer only applicable for Bumiputera and CDS account registered with Zulhilmi Zolkfli." />
        <meta itemProp="image" content="https://www.zulhilmizolkfli.com/ipo-miti-financing.png" />
        <link rel="canonical" href="https://www.zulhilmizolkfli.com/ipo-miti-application" />

        {/* Facebook Meta Tags */}
        <meta property="og:url" content="https://www.zulhilmizolkfli.com/ipo-miti-application" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Apply IPO through MITI with Margin Financing" />
        <meta property="og:description" content="Apply IPO through MITI and get margin financing up to 70%. This offer only applicable for Bumiputera and CDS account registered with Zulhilmi Zolkfli." />
        <meta property="og:image" content="https://www.zulhilmizolkfli.com/ipo-miti-financing.png" />
        <meta property="og:image:alt" content="Zulhilmi Zolkfli" />
        <meta property="og:site_name" content="Zulhilmi Zolkfli" />

        {/* Twitter Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Apply IPO through MITI with Margin Financing" />
        <meta name="twitter:description" content="Apply IPO through MITI and get margin financing up to 70%. This offer only applicable for Bumiputera and CDS account registered with Zulhilmi Zolkfli." />
        <meta name="twitter:image" content="https://www.zulhilmizolkfli.com/ipo-miti-financing.png" />
        <meta name="twitter:image:alt" content="Zulhilmi Zolkfli" />
        <meta name="twitter:site" content="@zulhilmizolkfli" />
        <meta name="twitter:creator" content="@zulhilmizolkfli" />
      </Helmet>
      <Header />
      <IpoMiti />
      <Footer />
    </>
  )
}

export default IpoMitiPage;