import React from 'react';
import './App.scss';
import NoAuthRoutes from './routes/NoAuthRoutes';

function App() {
  return (
    <>
      <div className="App">
        <NoAuthRoutes />
      </div>
    </>
  );
}

export default App;