import React from 'react';
import { Button, Col, Container, Row } from 'reactstrap';
import Slide from 'react-reveal/Slide';
import Bounce from 'react-reveal/Bounce';
import EliteImg from '../../assets/elite-macbook.png';

const WhatIsElite = () => {

  return(
    <>
      <Container className="pb-5">
        {/* <div className="divider"></div> */}
        <Slide bottom>
          <h2 className="text-center font-weight-bold pt-5">
            What Is Ringgit Lab Elite?
          </h2>
          <h3 className="mt-3 pb-4 grey-color text-center" style={{ fontSize: '28px' }}>
            Makes stock investing easier
          </h3>
        </Slide>
        <Row className="pt-5">
          <Col md={5} sm={12} xs={12} className="pb-5">
            <Bounce left>
              <img alt="Ringgit Lab Elite" src={EliteImg} style={{ width: '100%' }} />
            </Bounce>
          </Col>
          <Col md={1} sm={12} xs={12} className="pb-5"></Col>
          <Col md={6} sm={12} xs={12} className="pb-5">
            <Slide bottom>
              <h3 className="text-md-left text-center pb-3 font-weight-bold">
                Exclusive platform by Ringgit Lab!
              </h3>
              <h4 className="text-md-left text-center grey-color pb-3" style={{ fontSize: '24px' }}>
                Ringgit Lab Elite is an extended platform by Ringgit Lab to offer special service, exclusive for trading account holders under Zulhilmi Zolkfli.
              </h4>
              <div className="mt-5 text-center text-md-left">
                <a href="https://elite.ringgitlab.com/">
                  <h3 className="mb-0">
                    <Button className="primary-btn header-pill font-weight-bold">Learn more about Ringgit Lab Elite</Button>
                  </h3>
                </a>
              </div>
            </Slide>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default WhatIsElite;