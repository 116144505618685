import React from 'react';
import { Container, Alert } from 'reactstrap';
import IPOImg from '../../assets/ipo-miti-financing.png';

const IpoMiti = () => {

  return(
    <>
      <Container className="pt-5 pb-5">
        <h1 className="text-center pt-5 mb-3 font-weight-bold">
          Apply IPO Through MITI
        </h1>
        <h2 className="grey-color pt-3 text-center" style={{ fontSize: '28px' }}>
          Get margin financing up to 70% when you apply IPO through MITI. This offer only applicable for Bumiputera and CDS account registered with Zulhilmi Zolkfli.
        </h2>
        <div className="mt-5">
          <img alt="Zulhilmi Zolkfli: IPO Miti Financing CIMB MPlus" src={IPOImg} style={{ width: '100%' }} />
        </div>
        <Alert color="info" className="font-weight-bold mt-3">
          More info will be announced soon. If you have any inquiries about IPO application through MITI or need further confirmation before the application, please reach out to 019-4902302 or email to contact@zulhilmizolkfli.com.
        </Alert>
        {/* <h2 className="font-weight-bold pt-5 mb-4" style={{ fontSize: '28px' }}>Steps to apply IPO through MITI</h2>
        <h3 className="mt-5" style={{ fontSize: '20px' }}>
          1. Register an account on MITI portal from the link below.
        </h3>
        <h4 style={{ fontSize: '20px' }}>
          <a href="https://sahamonline.miti.gov.my/portal/register-individu" className="link-text" rel="noopener noreferrer" target="_blank">
            https://sahamonline.miti.gov.my/portal/register-individu
          </a>
        </h4>
        <h3 className="mt-5" style={{ fontSize: '20px' }}>
          2. Once you have complete your registration on MITI Portal, login and change your password.
        </h3>
        <h3 className="mt-5" style={{ fontSize: '20px' }}>
          3. Apply the listed IPO through MITI portal.
        </h3>
        <h3 className="mt-5" style={{ fontSize: '20px' }}>
          4. Wait for the result
        </h3> */}
      </Container>
    </>
  )
}

export default IpoMiti;