import React from 'react';
import { Route } from 'react-router-dom';
import CareerPage from '../components/landing/CareerPage';
import AboutPage from '../components/landing/AboutPage';
import Homepage from '../components/landing/Homepage';
import AccountOpeningPage from '../components/landing/AccountOpeningPage';
import IpoMitiPage from '../components/landing/IpoMitiPage';
import MPlusOnlinePage from '../components/landing/MPlusOnlinePage';

const NoAuthRoutes = () => {
  return (
    <>
      <Route exact path="/" component={Homepage} />
      <Route exact path="/about" component={AboutPage} />
      <Route exact path="/careers" component={CareerPage} />
      <Route exact path="/mplus-online-cds-registration" component={AccountOpeningPage} />
      <Route exact path="/mplus-online" component={MPlusOnlinePage} />
      <Route exact path="/ipo-miti-application" component={IpoMitiPage} />
    </>
  );
};

export default NoAuthRoutes;