import React from 'react';
import { Button, Col, Container, Row } from 'reactstrap';
import Slide from 'react-reveal/Slide';
import MPlusImg from '../../assets/mplus-macbook.png';
import EliteImg from '../../assets/elite-macbook.png';
import TelegramSupportImg from '../../assets/telegram-support-macbook.png';
import { Link } from 'react-router-dom';

const WhyUs = () => {

  return(
    <>
      <Container className="pb-5">
        <div className="divider"></div>
        <Slide bottom>
          <h2 className="text-center font-weight-bold pt-5">
            Why register CDS account with Zulhilmi Zolkfli?
          </h2>
          <h3 className="mt-3 pb-4 grey-color text-center" style={{ fontSize: '28px' }}>
            A reliable stockbroker and highly experienced in delivering the professional service
          </h3>
        </Slide>
        <Slide bottom>
          <Row className="mt-5">
            <Col md={3} sm={12} xs={12} className="mb-5">
              <img alt="Ringgit Lab Elite: MPlus Online" src={MPlusImg} style={{ width: '100%' }} />
            </Col>
            <Col md={9} sm={12} xs={12} className="mb-5 align-self-center">
              <h4 className="font-weight-bold" style={{ fontSize: '24px' }}>
                MPlus Online
              </h4>
              <h5 className="grey-color" style={{ fontSize: '22px' }}>
                Trade stocks with the most reliable trading platform by MPlus Online. MPlus Online offers one of the cheapest brokerage fee 
                in Malaysia, which is 0.08% or min RM 8.
              </h5>
            </Col>
            <Col md={3} sm={12} xs={12} className="mb-5">
              <img alt="Ringgit Lab Elite: MPlus Online" src={EliteImg} style={{ width: '100%' }} />
            </Col>
            <Col md={9} sm={12} xs={12} className="mb-5 align-self-center">
              <h4 className="font-weight-bold" style={{ fontSize: '24px' }}>
                Ringgit Lab Elite
              </h4>
              <h5 className="grey-color" style={{ fontSize: '22px' }}>
                Get free access to Ringgit Lab Elite, an exclusive platform by Ringgit Lab. Ringgit Lab Elite offers free learning materials, 
                paper trade, trading journal, stock screener, market news and other useful features to come soon.
              </h5>
            </Col>
            <Col md={3} sm={12} xs={12} className="mb-5">
              <img alt="Ringgit Lab Elite: MPlus Online" src={TelegramSupportImg} style={{ width: '100%' }} />
            </Col>
            <Col md={9} sm={12} xs={12} className="mb-5 align-self-center">
              <h4 className="font-weight-bold" style={{ fontSize: '24px' }}>
                Telegram Support Group
              </h4>
              <h5 className="grey-color" style={{ fontSize: '22px' }}>
                Get daily notifications about latest stock market news and watch list recommendations. This group will be our main platform for 
                discussion and interaction. 
              </h5>
            </Col>
          </Row>
        </Slide>
        <div className="mt-5 text-center">
          <Link to="/mplus-online-cds-registration">
            <h3 className="mb-0">
              <Button className="primary-btn header-pill font-weight-bold">Start trading stocks</Button>
            </h3>
          </Link>
        </div>
      </Container>
    </>
  )
}

export default WhyUs;