import React, { useEffect } from 'react';
import Header from '../header/Header';
import Footer from '../footer/Footer';
import CareerIntro from './CareerIntro';
import CareerSteps from './CareerSteps';
import CareerWhyUs from './CareerWhyUs';
import { Helmet } from 'react-helmet';

const CareerPage = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return(
    <>
      <Helmet>
        <title>Marketing Representative Career</title>
        <meta name="description" content="Career freedom with high income in financial industry. Work as Marketing Representative with a reliable stockbroker, Zulhilmi Zolkfli and gain your ultimate side income." />
        <meta itemProp="name" content="Marketing Representative Career" />
        <meta itemProp="description" content="Career freedom with high income in financial industry. Work as Marketing Representative with a reliable stockbroker, Zulhilmi Zolkfli and gain your ultimate side income." />
        <meta itemProp="image" content="https://www.zulhilmizolkfli.com/fpmr-sidc.png" />
        <link rel="canonical" href="https://www.zulhilmizolkfli.com/careers" />

        {/* Facebook Meta Tags */}
        <meta property="og:url" content="https://www.zulhilmizolkfli.com/careers" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Marketing Representative Career" />
        <meta property="og:description" content="Career freedom with high income in financial industry. Work as Marketing Representative with a reliable stockbroker, Zulhilmi Zolkfli and gain your ultimate side income." />
        <meta property="og:image" content="https://www.zulhilmizolkfli.com/fpmr-sidc.png" />
        <meta property="og:image:alt" content="Zulhilmi Zolkfli" />
        <meta property="og:site_name" content="Zulhilmi Zolkfli" />

        {/* Twitter Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Marketing Representative Career" />
        <meta name="twitter:description" content="Career freedom with high income in financial industry. Work as Marketing Representative with a reliable stockbroker, Zulhilmi Zolkfli and gain your ultimate side income." />
        <meta name="twitter:image" content="https://www.zulhilmizolkfli.com/fpmr-sidc.png" />
        <meta name="twitter:image:alt" content="Zulhilmi Zolkfli" />
        <meta name="twitter:site" content="@zulhilmizolkfli" />
        <meta name="twitter:creator" content="@zulhilmizolkfli" />
      </Helmet>
      <Header />
      <CareerIntro />
      <CareerWhyUs />
      <CareerSteps />
      <Footer />
    </>
  )
}

export default CareerPage;