import React, { useEffect } from 'react';
import Header from '../header/Header';
import Footer from '../footer/Footer';
import AboutMe from './AboutMe';
import { Helmet } from 'react-helmet';

const AboutPage = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return(
    <>
      <Helmet>
        <title>About Zulhilmi Zolkfli</title>
        <meta name="description" content="Learn more about Zulhilmi Zolkfli, a reliable stockbroker (remisier) at Malacca Securities whom highly experienced in delivering the professional customer service." />
        <meta itemProp="name" content="About Zulhilmi Zolkfli" />
        <meta itemProp="description" content="Learn more about Zulhilmi Zolkfli, a reliable stockbroker (remisier) at Malacca Securities whom highly experienced in delivering the professional customer service." />
        <meta itemProp="image" content="https://www.zulhilmizolkfli.com/zulhilmi.jpg" />
        <link rel="canonical" href="https://www.zulhilmizolkfli.com/about" />

        {/* Facebook Meta Tags */}
        <meta property="og:url" content="https://www.zulhilmizolkfli.com/about" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="About Zulhilmi Zolkfli" />
        <meta property="og:description" content="Learn more about Zulhilmi Zolkfli, a reliable stockbroker (remisier) at Malacca Securities whom highly experienced in delivering the professional customer service." />
        <meta property="og:image" content="https://www.zulhilmizolkfli.com/zulhilmi.jpg" />
        <meta property="og:image:alt" content="Zulhilmi Zolkfli" />
        <meta property="og:site_name" content="Zulhilmi Zolkfli" />

        {/* Twitter Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="About Zulhilmi Zolkfli" />
        <meta name="twitter:description" content="Learn more about Zulhilmi Zolkfli, a reliable stockbroker (remisier) at Malacca Securities whom highly experienced in delivering the professional customer service." />
        <meta name="twitter:image" content="https://www.zulhilmizolkfli.com/zulhilmi.jpg" />
        <meta name="twitter:image:alt" content="Zulhilmi Zolkfli" />
        <meta name="twitter:site" content="@zulhilmizolkfli" />
        <meta name="twitter:creator" content="@zulhilmizolkfli" />
      </Helmet>
      <Header />
      <AboutMe />
      <Footer />
    </>
  )
}

export default AboutPage;