import React from 'react';
import { Container, Button, Row, Col } from 'reactstrap';
import FounderImg from '../../assets/zulhilmi.png';
import Slide from 'react-reveal/Slide';
import { Link } from 'react-router-dom';

const Introduction = () => {

  return(
    <Container className="mt-5 mb-5">
      <Row className="pt-5">
        <Col md={7} sm={12} xs={12} className="pt-5 pb-5">
          <Slide bottom>
            <h1 className="text-md-left text-center font-weight-bold" style={{ fontSize: '36px' }}>
              Zulhilmi Zolkfli
            </h1>
            <h2 className="text-md-left text-center mt-4 pb-3 grey-color font-weight-bold" style={{ fontSize: '32px' }}>
              Stockbroker (Remisier) MPlus
            </h2>
            <h2 className="text-md-left text-center grey-color mt-5 pb-3" style={{ fontSize: '24px' }}>
              Start stock trading with MPlus Online. Open a trading account (CDS account) with Zulhilmi Zolkfli 
              and get free access to Ringgit Lab Elite.
            </h2>
            <div className="mt-5 text-md-left text-center">
              <Link to="/mplus-online-cds-registration">
                <h3 className="mb-0">
                  <Button className="primary-btn header-pill font-weight-bold">Open CDS account</Button>
                </h3>
              </Link>
            </div>
          </Slide>
        </Col>
        <Col md={2} sm={12} xs={12} className="pt-5 pb-5"></Col>
        <Col md={3} sm={12} xs={12} className="align-self-center pt-5 pb-5">
          <Slide bottom>
            <img alt="Ringgit Lab Elite: Zulhilmi Zolkfli" src={FounderImg} style={{ width: '100%' }} />
          </Slide>
        </Col>
      </Row>
    </Container>
  )
}

export default Introduction;