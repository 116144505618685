import React from 'react';
import { Button, Col, Container, Row } from 'reactstrap';
import Slide from 'react-reveal/Slide';
import MPlusEliteImg from '../../assets/elite-mplus-macbook-imac.jpg';
import { Link } from 'react-router-dom';

const WhyMPlus = () => {

  return(
    <div style={{ backgroundColor: '#fafafa' }}>
      <Container className="pb-5">
        <Slide bottom>
          <h2 className="text-center font-weight-bold pt-5">
            Why choose MPlus Online as your broker?
          </h2>
          <h3 className="mt-3 pb-4 grey-color text-center" style={{ fontSize: '28px' }}>
            Equipping traders for the win
          </h3>
        </Slide>
        <Slide bottom>
          <img alt="Ringgit Lab Elite: MPlus Online" src={MPlusEliteImg} style={{ width: '100%', borderRadius: '10px', boxShadow: 'rgba(50, 50, 93, 0.109804) 1px 2px 2px, rgba(0, 0, 0, 0.0784314) 1px 1px 2px' }} />
        </Slide>
        <Slide bottom>
          <Row className="mt-5">
            <Col md={4} sm={12} xs={12} className="mb-5 text-center">
              <h4 className="font-weight-bold" style={{ fontSize: '24px' }}>Low brokerage fee</h4>
              <h5 className="grey-color" style={{ fontSize: '20px' }}>
                MPlus Online offers among the cheapest brokerage fee in the market with 0.08% or minimum RM8.
              </h5>
            </Col>
            <Col md={4} sm={12} xs={12} className="mb-5 text-center">
              <h4 className="font-weight-bold" style={{ fontSize: '24px' }}>Intuitive trading platform</h4>
              <h5 className="grey-color" style={{ fontSize: '20px' }}>
                MPlus Online platform allows you to trade easily while equipping you with fundamental and technical analysis.
              </h5>
            </Col>
            <Col md={4} sm={12} xs={12} className="mb-5 text-center">
              <h4 className="font-weight-bold" style={{ fontSize: '24px' }}>Excellent customer support</h4>
              <h5 className="grey-color" style={{ fontSize: '20px' }}>
                MPlus Online provides great support and client service with daily stock analysis and recommendation.
              </h5>
            </Col>
          </Row>
        </Slide>
        <div className="mt-5 text-center">
          <Link to="/mplus-online-cds-registration">
            <h3 className="mb-0">
              <Button className="primary-btn header-pill font-weight-bold">Open CDS account with MPlus Online</Button>
            </h3>
          </Link>
        </div>
      </Container>
    </div>
  )
}

export default WhyMPlus;