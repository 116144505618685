import React, { useEffect } from 'react';
import Header from '../header/Header';
import Introduction from './Introduction';
import Footer from '../footer/Footer';
import WhyUs from './WhyUs';
import WhyMPlus from './WhyMPlus';
import WhatIsElite from './WhatIsElite';
import { Helmet } from 'react-helmet';

const Homepage = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return(
    <>
      <Helmet>
        <title>Zulhilmi Zolkfli: Remisier MPlus</title>
        <meta name="description" content="Open MPlus Online CDS account with Zulhilmi Zolkfli, a licensed stockbroker (remisier) from Malacca Securities and get free access to Ringgit Lab Elite." />
        <link rel="canonical" href="https://www.zulhilmizolkfli.com" />
      </Helmet>
      <Header />
      <Introduction />
      <WhyUs />
      <WhyMPlus />
      {/* <WhyTradeStocks /> */}
      <WhatIsElite />
      <Footer />
    </>
  )
}

export default Homepage;